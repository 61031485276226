.App {
    text-align: center;
}

body {
    max-width: 100%;
    overflow-x: hidden;
    font-family: "San Francisco";
}
@font-face {
    font-family: "San Francisco";
    font-weight: 400;
    src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff");
}
.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.expandable {
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    max-height: 0;
}

.expandable:target {
    max-height: 8000px;
}

a {
    text-decoration: none;
}

a,
a:visited,
a:hover,
a:active {
    color: inherit;
}
