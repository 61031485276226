.hero-section {
    background-color: #000000;
    background-size: cover;
    background-position: center;
    height: 100vh;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;
    overflow: hidden;
}
.hero-title {
    font-size: 8vw;
    color: #d1feb8;
    margin: 0.5em;
}

.hero-subtitle {
    font-size: 5vw;
    color: #fff;
    margin: 0.5em;
}

.text-container {
    height: 40vh;
}

.cta-button {
    margin: 1vw;
    color: #f8f9fa;
    background-color: #000;
    padding: 1vw 2vw;
    text-decoration: none;
    font-size: 1.5vw;
    border-radius: 1em;
    border-color: #f8f9fa;
    border: 0.25em solid #f8f9fa;
}

.green-iphone {
    position: absolute;
    animation: fadeIn 5s;
    width: 100%;
    left: 0;
    bottom: 0;
    max-height: 40vh;
    object-fit: contain;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* @media screen and (max-width: 400px) {
    .hero-title {
        font-size: 5em;
    }

    .hero-subtitle {
        font-size: 3em;
    }
} */

@media screen and (max-height: 667px) {
    /* .hero-title {
        font-size: 5em;
    }

    .hero-subtitle {
        font-size: 3em;
    } */

    .green-iphone {
        display: none;
    }
}
